.container {
    display: flex;
    height: 100%;
    padding: 0 5%;
}
.results {
    display: flex;
    flex-direction: column;
    margin: 1%;
    border-radius: 1em;
    width: 20%;
    height: auto;
    background-color: white;
    filter: drop-shadow(.5rem .5rem 1rem grey)
}
.results-header {
    margin: 0 5%;
}
.scroll-content {
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 5%;
}
.details {
    margin: 1%;
    border-radius: 1em;
    background-color: white;
    padding: 2%;
    width: 80%;
    filter: drop-shadow(.5rem .5rem 1rem grey)
}
.selected {
    font-weight: bold;
    background-color:lightskyblue;
}
.result-item {
    margin: 0;
    padding: 2% 5% 2% 10%;
    cursor: pointer;
}
.select-result {
    display: flex;
    justify-content: center;
}