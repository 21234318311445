.details-container {
    padding: 0 5%;
    display: flex;
    justify-content: space-around;
}

.map{
    padding: 2%;
    display: block;
    width: 30vw;
}

