body {
  background-color: #8F1436;
}

.header {
  height: 5vh;
  padding: 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: #8F1436;
  filter: drop-shadow(0 0 1rem grey)
}

.page-content {
  overflow: hidden;
  padding-top: 1%;
  height: 85vh;
  background-color: lightgrey;
}

.header-branding {
  width: 50%;
  display: flex;
  align-items: center;
}

.header-text{
  font-size: 5vh;
  font-family: 'Roboto Condensed', sans-serif;
}

.logo {
  height: 5vh;
  filter: invert();
  padding-right: 2%;
}

::-webkit-scrollbar {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #8F1436;
}